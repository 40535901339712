import {Controller} from "stimulus"
// import '../modules/isotope.pkgd'
import $ from 'jquery';

// import 'isotope-layout'
// import isotope from 'isotope-layout'
// window.isotope = isotope;

import Isotope from 'isotope-layout'

export default class extends Controller {

    connect() {
        let grid = new Isotope('.catalog-body', {
            itemSelector: '.element-item'/*,
            layoutMode: 'fitRows'*/
        });

        // filter functions
        let filterFns = {
            // show if number is greater than 50
            numberGreaterThan50: function () {
                let number = $(this).find('.number').text();
                console.log(number);
                return parseInt(number, 10) > 50;
            },
            // show if name ends with -ium
            ium: function () {
                let name = $(this).find('.name').text();
                return name.match(/ium$/);
            }
        };

// bind filter button click
        $('#filters').on('click', 'button', function () {
            let filterValue = $(this).attr('data-filter');
            // use filterFn if matches value
            /*filterValue = filterFns[ filterValue ] || filterValue;*/
            /*grid.isotope({ filter: filterValue });*/

            console.log(filterValue);
            /*console.log(grid);*/
            grid.arrange({filter: filterValue});
            grid.arrange();
        });

// bind sort button click
        $('#sorts').on('click', 'button', function () {
            let sortByValue = $(this).attr('data-sort-by');
            console.log('sortByValue');
            console.log(sortByValue);
            /*grid.isotope({ sortBy: sortByValue });*/
            grid.arrange({sortBy: sortByValue});
        });

// change is-checked class on buttons
        $('.button-group').each(function (i, buttonGroup) {
            let btnGroup = $(buttonGroup);
            btnGroup.on('click', 'button', function () {
                btnGroup.find('.is-checked').removeClass('is-checked');
                $(this).addClass('is-checked');
            });
        });


        // $('.grid').isotope({
        //     // options
        //     itemSelector: '.grid-item',
        //     layoutMode: 'fitRows'
        // });

        // let elem = document.querySelector('.grid');
        // let iso = new Isotope( elem, {
        //     // options
        //     itemSelector: '.grid-item',
        //     layoutMode: 'fitRows'
        // });

        // element argument can be a selector string
        //   for an individual element
        //         let iso = new Isotope( '.grid', {
        //             // options
        //         });
    }
}