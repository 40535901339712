import {Controller} from "stimulus"
import $ from 'jquery';

import '../modules/chartjs'

let createChart = (id = null) => {
    let container = document.getElementById('survey_estadistics');

    let data_chart = []

    $.ajax({
        url: location.origin + '/commercial/admin/graph',
        data: {
            id: id
        },
        type: 'GET',
        beforeSend: function () {
            container.innerHTML = `<span class="fa fa-spiner fa-spin fa-2x"></span><br>Cargando datos de encuesta.`
        },
        success: function (data) {
            let html = '<div class="row ">';
            if (data.charts.length > 0) {
                data.charts.map(elem => {
                    let id_chart = elem.label.split(' ').join('_');
                    document.getElementById('survey_name').innerHTML = data.survey;
                    html += `<div class="col-md-6 col-12 mb-4 p-4">
                                <canvas id="my_chart_${id_chart}" style="background-color: rgba(0, 0, 0, .1);
                                        border-radius: 5px;
                                        position: relative;
                                        display: block;
                                        width: 100%;
                                        ">
                                </canvas>

                            </div>`;
                })
                ;
            }
            html += "</div>"
            container.innerHTML = html;

            setTimeout(() => {
                data.charts.map(elem => {
                    let id_chart = elem.label.split(' ').join('_');
                    let ctx = document.getElementById(`my_chart_${id_chart}`);

                    console.log(id_chart);
                    console.log(ctx);


                    let data_chart = {
                        labels: [elem.label],
                        datasets: []
                    };

                    for (let i = 0; i < elem.data.length; i++) {
                        data_chart['datasets'].push({
                            label: elem.data_labels[i],
                            data: [elem.data[i]],
                            backgroundColor: [elem.backgroundColor[i]],
                            hoverOffset: 4
                        })
                    }


                    new Chart(ctx.getContext('2d'), {
                        // type: 'polarArea',
                        // type: 'bar',
                        type: 'doughnut',
                        // type: 'pie',
                        // type: 'line',
                        // type: 'radar',
                        data: data_chart,
                        options: {},
                        plugins: []
                    })
                })
            }, 1000)
            // const chart = new Chart(ctx.getContext('2d'), {
            //     // type: 'polarArea',
            //     // type: 'bar',
            //     // type: 'doughnut',
            //     type: 'pie',
            //     // type: 'line',
            //     // type: 'radar',
            //     data: data,
            //     options: {},
            //     plugins: []
            // });
        },
        error: function (error) {
            container.innerHTML = `<div class="d-flex flex-column justify-content-center align-items-center"> <span class="fa fa-times fa-2x text-muted"></span>
                                    <p class="text-danger" style="font-size: 5em;">${error.status}</p>
                                    <p class="text-muted" style="font-size: 2.5em;">${error.statusText}</p></div>`;
        }

    })


}

let CreateTable = () => {

}

let ShowTable2 = () => {
    let select_survey = $('#select_survey').val();
    if (select_survey === '' || select_survey === undefined) {
        toastr.info('Seleccione una encuesta', 'Encuesta');
    } else {
        let container = document.getElementById('survey_table');

        $.ajax({
            url: location.origin + '/commercial/admin/table',
            data: {
                id: select_survey
            },
            type: 'GET',
            beforeSend: function () {
                container.innerHTML = `<span class="fa fa-spiner fa-spin fa-2x"></span><br>Cargando datos de encuesta.`
            },
            success: function (data) {
                // Definir el orden deseado
                const order = ["Excelente", "Muy Bien", "Bien", "Regular", "Mal"];

                const sortedData = Object.keys(data.data).reduce((acc, key) => {
                    const responses = data.data[key];
                    // Ordenar las respuestas según el orden especificado
                    const sortedResponses = order.reduce((accResp, resp) => {
                        if (responses[resp]) {
                            accResp[resp] = responses[resp];
                        }
                        return accResp;
                    }, {});
                    acc[key] = sortedResponses;
                    return acc;
                }, {});

                // Eliminar preguntas vacías
                for (let key in sortedData) {
                    if (Object.keys(sortedData[key]).length === 0) {
                        delete sortedData[key];
                    }
                }

                let table_survery = '';
                Object.entries(sortedData).forEach(([key, value]) => {
                    table_survery += `<div>
                                            <h5>'${key}'</h5>
                                          </div>
                                          <table class="table table-sm table-striped" style="margin-bottom: 2rem">
                                            <tr>
                                              <th>Respuesta</th>
                                              <th>Cantidad</th>
                                            </tr>`;
                    Object.entries(value).forEach(([k, v]) => {
                        table_survery += `
                                                 <tr>
                                                   <td>${k}</td>
                                                   <td>${v}</td>
                                                 </tr>
                                               `

                    });
                    table_survery += `</table>`;
                });

                container.innerHTML = table_survery;
            }
        })
    }
}

let ShowChart2 = (type_chart) => {
    let select_survey = $('#select_survey').val();
    if (select_survey === '' || select_survey === undefined) {
        toastr.info('Seleccione una encuesta', 'Encuesta');
    } else {
        let container = document.getElementById('survey_estadistics');

        $.ajax({
            url: location.origin + '/commercial/admin/table',
            data: {
                id: select_survey
            },
            type: 'GET',
            beforeSend: function () {
                container.innerHTML = `<span class="fa fa-spiner fa-spin fa-2x"></span><br>Cargando datos de encuesta.`
            },
            success: function (data) {
                // Definir el orden deseado
                const order = ["Excelente", "Muy Bien", "Bien", "Regular", "Mal"];

                const sortedData = Object.keys(data.data).reduce((acc, key) => {
                    const responses = data.data[key];
                    // Ordenar las respuestas según el orden especificado
                    const sortedResponses = order.reduce((accResp, resp) => {
                        if (responses[resp]) {
                            accResp[resp] = responses[resp];
                        }
                        return accResp;
                    }, {});
                    acc[key] = sortedResponses;
                    return acc;
                }, {});

                // Eliminar preguntas vacías
                for (let key in sortedData) {
                    if (Object.keys(sortedData[key]).length === 0) {
                        delete sortedData[key];
                    }
                }

                let html = `<div class="row w-100">
                                     <div class="w-100 d-flex justify-content-end">
                                       <div data-action="click->chart#changeTypeChart" style="width: 50px; height: 50px">
                                         <img src="/img/img-${(type_chart === 'pie') ? 'doughnut' : 'pie'}.jpg" alt="D" style="width: 100%; object-fit: cover;">
                                       </div>
                                     </div>
                                    `;
                let id_chart = 1;
                Object.entries(sortedData).forEach(([key, value]) => {
                    document.getElementById('survey_name').innerHTML = '';
                    html += `<div class="col-12 mb-4">
                               <div>
                                 <h5>'${key}'</h5>
                               </div>
                               <canvas id="my_chart_${id_chart}" style="background-color: rgba(0, 0, 0, .1);
                                 border-radius: 5px;
                                 position: relative;
                                 display: block;
                                 width: 100%;">
                               </canvas>
                             </div>`;
                    id_chart += 1;
                });
                html += "</div>"
                container.innerHTML = html;

                let id_chart_index = 1;
                let id_chart_index_array = [];
                Object.entries(sortedData).forEach(([key, value]) => {
                    let labelss = [];
                    let datass = [];
                    Object.entries(value).forEach(([k, v]) => {
                        labelss.push(k);
                        datass.push(v);

                        id_chart_index_array[id_chart_index] = [];
                        id_chart_index_array[id_chart_index]['label'] = labelss;
                        id_chart_index_array[id_chart_index]['data'] = datass;
                    });

                    id_chart_index += 1;
                });

                let id_chart_cha = 1;
                Object.entries(id_chart_index_array).forEach(([key, value]) => {
                    /* Crear array de colores dependiendo de la cantidad de respuestas */
                    let bg_color = [];
                    Object.entries(value['label']).forEach(([key, value]) => {
                        bg_color.push(stringToRgbColor(value));
                    });
                    /* Crear array de colores dependiendo de la cantidad de respuestas FIN */

                    const data_set = {
                        labels: value['label'],
                        datasets: [{
                            label: 'Cantidad',
                            data: value['data'],
                            /*backgroundColor: [
                                'rgb(255, 99, 132)',
                                'rgb(54, 162, 235)',
                                'rgb(255, 205, 86)'
                            ],*/
                            backgroundColor: bg_color,
                            /*backgroundColor: generateRgbGradientColors(value['label']).reverse(),*/
                            hoverOffset: 12,
                            hoverBackgroundColor: 'rgb(52, 58, 64)'
                        }]
                    };

                    const actions = [
                        {
                            name: 'Cambiar a la vista Doughnut',
                            handler(myyChartt) {
                                if (myyChartt.options.cutout) {
                                    myyChartt.options.cutout = 0;
                                } else {
                                    myyChartt.options.cutout = '50%';
                                }
                                myyChartt.update();
                            }
                        }
                    ];

                    let ctx = document.getElementById(`my_chart_${id_chart_cha}`);
                    new Chart(ctx.getContext('2d'), {
                        // type: 'polarArea',
                        // type: 'bar',
                        // type: 'doughnut',
                        type: type_chart,
                        // type: 'line',
                        // type: 'radar',
                        data: data_set,
                        options: {
                            legend: {
                                display: false // Para quitar la leyenda, establece 'display' en 'false'
                            }
                        },
                        plugins: []
                    });

                    /*setTimeout(() => {
                        data.charts.map(elem => {
                            let id_chart = elem.label.split(' ').join('_');
                            let ctx = document.getElementById(`my_chart_${id_chart}`);

                            console.log(id_chart);
                            console.log(ctx);


                            let data_chart = {
                                labels: [elem.label],
                                datasets: []
                            };

                            for (let i = 0; i < elem.data.length; i++) {
                                data_chart['datasets'].push({
                                    label: elem.data_labels[i],
                                    data: [elem.data[i]],
                                    backgroundColor: [elem.backgroundColor[i]],
                                    hoverOffset: 4
                                })
                            }


                            new Chart(ctx.getContext('2d'), {
                                // type: 'polarArea',
                                // type: 'bar',
                                type: 'doughnut',
                                // type: 'pie',
                                // type: 'line',
                                // type: 'radar',
                                data: data_chart,
                                options: {},
                                plugins: []
                            })
                        })
                    }, 1000)*/
                    id_chart_cha += 1;
                });
            }
        })
    }
}

let generateRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
}
let stringToRgbColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let r = (hash & 0xFF0000) >> 16;
    let g = (hash & 0x00FF00) >> 8;
    let b = hash & 0x0000FF;
    return `rgb(${r}, ${g}, ${b})`;
}
let generateRgbGradientColors = (list) => {
    const colors = [];
    const steps = list.length;
    const stepSize = 255 / (steps - 1); // Calcula el tamaño del paso para cada componente de color

    for (let i = 0; i < steps; i++) {
        // Calcula el valor de cada componente de color basado en la posición
        const r = Math.round(255 - (i * stepSize)); // Comienza en rojo y disminuye
        const g = Math.round(i * stepSize); // Comienza en verde y aumenta
        const b = 0; // Componente azul fijo para este gradiente

        // Añade el color RGB al array
        colors.push(`rgb(${r}, ${g}, ${b})`);
    }

    return colors;
}







let ShowTable = (container, sortedData) => {
    let table_survery = '';
    Object.entries(sortedData).forEach(([key, value]) => {
        table_survery += `<div>
                                            <h5>${key}</h5>
                                          </div>
                                          <table class="table table-sm table-striped" style="margin-bottom: 2rem">
                                            <tr>
                                              <th>Respuesta</th>
                                              <th>Cantidad</th>
                                              <th>Porciento</th>
                                            </tr>`;
        let total_value = Object.values(value).reduce((acc, val) => acc + val, 0);
        Object.entries(value).forEach(([k, v]) => {
            table_survery += `
                                                 <tr>
                                                   <td>${k}</td>
                                                   <td>${v}</td>
                                                   <td>${Math.round((v / total_value) * 100)}%</td>
                                                 </tr>
                                               `

        });
        table_survery += `</table>`;
    });

    container.innerHTML = table_survery;
}

let ShowChart = (container, sortedData, type_chart) => {
    let html = `<div class="row w-100">
                                     <div class="w-100 d-flex justify-content-end align-items-center" data-action="click->chart#changeTypeChart">
                                       <div data-action="click->chart#changeTypeChart">
                                         <h6 data-action="click->chart#changeTypeChart" style="margin: 0 20px 0 0;">
                                           Vista ${(type_chart === 'pie') ? 'Donut' : 'Pastel'}
                                         </h6>
                                       </div>
                                       <div data-action="click->chart#changeTypeChart" style="width: 50px; height: 50px">
                                         <img src="/img/img-${(type_chart === 'pie') ? 'doughnut' : 'pie'}.jpg" alt="D" style="width: 100%; object-fit: cover;">
                                       </div>
                                     </div>
                                    `;
    let id_chart = 1;
    Object.entries(sortedData).forEach(([key, value]) => {
        document.getElementById('survey_name').innerHTML = '';
        html += `<div class="col-3 mb-4">
                               <div>
                                 <h5 style="margin-bottom: 1rem;">${key}</h5>
                               </div>
                               <canvas id="my_chart_${id_chart}" style="background-color: rgba(0, 0, 0, .1);
                                 border-radius: 5px;
                                 position: relative;
                                 display: block;
                                 width: 100%;">
                               </canvas>
                             </div>`;
        id_chart += 1;
    });
    html += "</div>"
    container.innerHTML = html;

    let id_chart_index = 1;
    let id_chart_index_array = [];
    Object.entries(sortedData).forEach(([key, value]) => {
        let labelss = [];
        let datass = [];
        Object.entries(value).forEach(([k, v]) => {
            labelss.push(k);
            datass.push(v);

            id_chart_index_array[id_chart_index] = [];
            id_chart_index_array[id_chart_index]['label'] = labelss;
            id_chart_index_array[id_chart_index]['data'] = datass;
        });

        id_chart_index += 1;
    });

    let id_chart_cha = 1;
    Object.entries(id_chart_index_array).forEach(([key, value]) => {
        /* Crear array de colores dependiendo de la cantidad de respuestas */
        let bg_color = [];
        Object.entries(value['label']).forEach(([key, value]) => {
            bg_color.push(stringToRgbColor(value));
        });
        /* Crear array de colores dependiendo de la cantidad de respuestas FIN */

        const data_set = {
            labels: value['label'],
            datasets: [{
                label: 'Cantidad',
                data: value['data'],
                /*backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)'
                ],*/
                backgroundColor: bg_color,
                /*backgroundColor: generateRgbGradientColors(value['label']).reverse(),*/
                hoverOffset: 12,
                hoverBackgroundColor: 'rgb(52, 58, 64)'
            }]
        };

        const total = data_set.datasets[0].data.reduce((a, b) => a + b, 0);

        /* Calcular y agregar los porcentajes a las etiquetas */
        data_set.labels = data_set.labels.map((label, index) => {
            const percentage = ((data_set.datasets[0].data[index] / total) * 100).toFixed(2);
            return `${label} (${percentage}%)`;
        });

        const actions = [
            {
                name: 'Cambiar a la vista Doughnut',
                handler(myyChartt) {
                    if (myyChartt.options.cutout) {
                        myyChartt.options.cutout = 0;
                    } else {
                        myyChartt.options.cutout = '50%';
                    }
                    myyChartt.update();
                }
            }
        ];

        let ctx = document.getElementById(`my_chart_${id_chart_cha}`);
        new Chart(ctx.getContext('2d'), {
            // type: 'polarArea',
            // type: 'bar',
            // type: 'doughnut',
            type: type_chart,
            // type: 'line',
            // type: 'radar',
            data: data_set,
            options: {
                plugins: {
                    legend: {
                        // display: false // Para quitar la leyenda, establece 'display' en 'false'
                        position: 'bottom'
                    }
                }
            },
            plugins: []
        });

        /*document.getElementById(`my_chart_${id_chart_cha}`).style.backgroundColor = '#ffffff';*/

        /*setTimeout(() => {
            data.charts.map(elem => {
                let id_chart = elem.label.split(' ').join('_');
                let ctx = document.getElementById(`my_chart_${id_chart}`);

                console.log(id_chart);
                console.log(ctx);


                let data_chart = {
                    labels: [elem.label],
                    datasets: []
                };

                for (let i = 0; i < elem.data.length; i++) {
                    data_chart['datasets'].push({
                        label: elem.data_labels[i],
                        data: [elem.data[i]],
                        backgroundColor: [elem.backgroundColor[i]],
                        hoverOffset: 4
                    })
                }


                new Chart(ctx.getContext('2d'), {
                    // type: 'polarArea',
                    // type: 'bar',
                    type: 'doughnut',
                    // type: 'pie',
                    // type: 'line',
                    // type: 'radar',
                    data: data_chart,
                    options: {},
                    plugins: []
                })
            })
        }, 1000)*/
        id_chart_cha += 1;
    });
}

let DataForChartAndTable = (type_chart) => {
    let select_survey = $('#select_survey').val();
    if (select_survey === '' || select_survey === undefined) {
        toastr.info('Seleccione una encuesta', 'Encuesta');
    } else {
        let container_table = document.getElementById('survey_table');
        let container_chart = document.getElementById('survey_estadistics');

        $.ajax({
            url: location.origin + '/commercial/admin/table',
            data: {
                id: select_survey
            },
            type: 'GET',
            beforeSend: function () {
                container_table.innerHTML = `<div class="d-flex justify-content-center align-items-center"><span class="fa fa-spinner fa-spin fa-2x" style="margin-right: 0.5rem;"></span><br>Cargando datos de encuesta.</div>`
                container_chart.innerHTML = `<span class="fa fa-spinner fa-spin fa-2x" style="margin-right: 0.5rem;"></span><br>Cargando datos de encuesta.`
            },
            success: function (data) {
                /*// Definir el orden deseado
                const order = ["Excelente", "Muy Bien", "Bien", "Regular", "Mal"];

                const sortedData = Object.keys(data.data).reduce((acc, key) => {
                    const responses = data.data[key];
                    // Ordenar las respuestas según el orden especificado
                    const sortedResponses = order.reduce((accResp, resp) => {
                        if (responses[resp]) {
                            accResp[resp] = responses[resp];
                        }
                        return accResp;
                    }, {});
                    acc[key] = sortedResponses;
                    return acc;
                }, {});*/

                // Eliminar preguntas vacías
                for (let key in data.data) {
                    if (Object.keys(data.data[key]).length === 0) {
                        delete data.data[key];
                    }
                }

                if (Object.keys(data.data).length === 0) {
                    toastr.warning('No se ha respondido esta encuesta, seleccione otra.', 'Encuesta');
                    container_table.innerHTML = '';
                    container_chart.innerHTML = '';
                } else {
                    ShowTable(container_table, data.data);
                    ShowChart(container_chart, data.data, type_chart);
                }
            }
        });
    }
}

export default class extends Controller {
    connect() {
        /*// createChart();*/
        // ShowChart();
        /*ShowTable();*/

        DataForChartAndTable('pie');

        $('#select_survey').select2({
            theme: "bootstrap",
            language: "es",
            placeholder: "Seleccione una opción",
        }).on('change', function (e) {
            /*document.getElementById('survey_name').innerText = e.target.selectedOptions[0].text
            createChart(e.target.value);*/
            // ShowTable();
            // ShowChart('pie');

            DataForChartAndTable('pie');
        });
    }

    changeTypeChart() {
        const canvasElement = $('#my_chart_1');
        const chartInstance = Chart.getChart(canvasElement[0]); // Asume que 'canvasElement' es una colección de elementos jQuery

        if (chartInstance !== undefined) {
            const chartType = chartInstance.config.type;

            if (chartType === 'pie') {
                /*ShowChart('doughnut');*/
                DataForChartAndTable('doughnut');
            } else {
                /*ShowChart('pie');*/
                DataForChartAndTable('pie');
            }
        }
    }

    /*changeTypeChart(e) {
        console.log(e);
        console.log(e.target.dataset.type);

        const canva = document.getElementById('my_chart_1');
        const cc = canva.getContext('2d');
        const canvasId = 'my_chart_1';
        // Obtiene la instancia del gráfico
        let chart = Chart.getChart(canvasId);

        chart.destroy();

        // Cambia el tipo de gráfico según los datos
        let newType = e.target.dataset.type === 'pie' ? 'doughnut' : 'pie';

        const data_set = {
            labels: ['a', 'b', 'c'],
            datasets: [{
                label: 'Cantidad',
                data: [2, 4, 5],
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)'
                ],
                hoverOffset: 12,
                hoverBackgroundColor: 'rgb(52, 58, 64)'
            }]
        };

        // Crea un nuevo gráfico con el nuevo tipo
        chart = new Chart(cc, {
            type: newType,
            data: data_set, // Asegúrate de que tus datos están actualizados si es necesario
            options: {} // Tus opciones
        });
    }*/
}