import {Controller} from "stimulus"
import $ from 'jquery';
import popper from '@popperjs/core'

export default class extends Controller {

    initialize() {

    }

    connect() {
        let task_type = $('#task_scheduler_task_type');
        let task_id = $('#task_scheduler_task_id');

        task_id.select2({
            theme: 'bootstrap',
            placeholder: 'Seleccione una opción',
            allowClear: true
        }).prop('disabled', 'true');

        task_type.select2({
            theme: 'bootstrap',
            placeholder: 'Seleccione una opción',
            allowClear: true
        }).on('change', function (e) {
            if (e.target.value.isEmpty()) {
                task_id.empty();
                task_id.prop('disabled', 'true');
            } else {
                task_id.removeAttr('disabled');
                task_id.select2({
                    theme: 'bootstrap',
                    placeholder: 'Seleccione una opción',
                    allowClear: true,
                    ajax: {
                        url: `${location.origin}/admin/task_scheduler/get-task`,
                        data: function (params) {
                            return {
                                search: params.term,
                                page: params.page || 1,
                                type: e.target.value
                            }

                        },
                        type: "GET",
                        processResults: function (data, params) {
                            let result = [];

                            if (data.length > 0) {
                                data.map((child, number) => {
                                        result.push({
                                            id: child.id,
                                            text: child.name
                                        });
                                })


                            }


                            if (result.length === 0) {
                                result.push({
                                    text: "No hay datos para mostrar", children: []
                                });
                            }

                            return {
                                results: result
                            }
                        }
                    }
                }).on('change', function (e) {

                })
            }
        })
    }
}