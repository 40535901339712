// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import {Turbo} from "@hotwired/turbo-rails"
import $ from 'jquery';

import * as ActiveStorage from "@rails/activestorage"
import "channels"

//Bootstrap
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import 'bootstrap/dist/css/bootstrap';
import "flatpickr/dist/flatpickr.css"
import "flatpickr/dist/themes/airbnb.css"
import 'animate.css'
import "@fortawesome/fontawesome-free/css/all"
import "font-awesome/scss/font-awesome.scss"
import 'css/icon-simpleline/scss/simple-line-icons'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import 'chartkick'
import 'chartkick/highcharts'
import 'chart.js'
// window.chartjs = chartjs
import toastr from 'toastr'
import 'toastr/toastr.scss'
import ScrollMagic from 'scrollmagic'
window.ScrollMagic = ScrollMagic

import '../../assets/javascripts/serviceworker-companion'
//=require global/animation.gsap
//=require global/animation.velocity

window.toastr = toastr
Chartkick.configure ({language: "es", mapsApiKey: "AIzaSyCJRQ7qfhPNlFrKRa9yPWUCv837ycrGESU"})
//= require_tree.


Rails.start ()
Turbo.session.drive = false
Turbo.start ()
ActiveStorage.start ()

import "wowjs/css/libs/animate"

import "controllers"

require ("trix")
require ("@rails/actiontext")
require ("@rails/activestorage").start ();


//= require i18n
//= require i18n.js
//= require i18n/translations
import I18n from 'i18n-js'

require ('../i18n/translation')
window.I18n = I18n


$ (document).on ('turbolinks:load', () => {
    let locale = $ ('body').data ('locale');
    I18n.defaultLocale = locale
    I18n.locale = locale
})

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim ());
}

import '../modules/fancybox.umd';




