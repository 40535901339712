import {Controller} from "stimulus"
import $ from 'jquery';
import bootbox from 'bootbox/bootbox';
import toastr from 'toastr/toastr'
import 'toastr/toastr.scss'
import 'bootstrap-table/dist/bootstrap-table.min.css'
import 'bootstrap-table/dist/bootstrap-table.min'
import 'bootstrap-table/dist/locale/bootstrap-table-es-ES.min'


export default class extends Controller {

    connect() {

        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": true,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "3000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }

        let table = $('#table');
        let admin_table = $('#admin_table');
        let revision_table = $('#revision_table');
        let revision_history_table = $('#revision_history_table');
        table.bootstrapTable({
            language: 'es',
            pagination: true,
            search: true,
            refresh: true,
            sidePagination: "server",
            showRefresh: "true",
            pageSize: 10,
            pageList: "[10, 25, 50, 100, 200, All]",
            toolbar: '#toolbar',
            toolbarAlign: "left",
            idField: "id",
            selectItemName: "id",
            onLoadSuccess: function (data) {
                table.bootstrapTable('checkInvert');
            }

        });

        admin_table.bootstrapTable({
            language: 'es',
            pagination: true,
            search: false,
            refresh: true,
            sidePagination: "server",
            showRefresh: "true",
            pageSize: 5,
            pageList: "[5, 25, 50, 100, 200, All]",
            toolbar: '#toolbar',
            toolbarAlign: "left",
            idField: "id",
            selectItemName: "id",
            onLoadSuccess: function (data) {
                table.bootstrapTable('checkInvert');
            }

        });
        revision_table.bootstrapTable({
            language: 'es',
            pagination: true,
            search: true,
            refresh: true,
            sidePagination: "server",
            showRefresh: "true",
            pageSize: 5,
            pageList: "[5, 25, 50, 100, 200, All]",
            toolbar: '#toolbar',
            toolbarAlign: "left",
            idField: "id",
            selectItemName: "id",
            onLoadSuccess: function (data) {
                table.bootstrapTable('checkInvert');
            }

        });
        revision_history_table.bootstrapTable({
            language: 'es',
            pagination: true,
            search: true,
            refresh: true,
            sidePagination: "server",
            showRefresh: "true",
            pageSize: 5,
            pageList: "[5, 25, 50, 100, 200, All]",
            toolbar: '#toolbar',
            toolbarAlign: "left",
            idField: "id",
            selectItemName: "id",
            onLoadSuccess: function (data) {
                table.bootstrapTable('checkInvert');
            }

        });

        $('table_client').bootstrapTable();

        $('#checkBtn').on('change', function () {
            $('.inputBtnDataTable').prop('checked', $(this).is(':checked'));
        })


    }


    delete(e) {
        bootbox.confirm({
            message: "¿Seguro que desea eliminar este elemento?",
            buttons: {
                confirm: {
                    label: '<i class="fa fa-check"></i> Aceptar',
                    className: 'btn-success'
                },
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancelar',
                    className: 'btn-secondary'
                }
            },
            size: 'small',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: e.target.dataset.target + '/delete',
                        data: {
                            format: 'json'
                        },
                        type: 'GET',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(data.msg);
                                $('#table').bootstrapTable('refresh');
                            } else {
                                toastr.error(data.msg);
                                // console.log(data.msg);
                            }
                        }
                    })
                }
            }
        })

    }

    block(e) {
        bootbox.confirm({
            message: "¿Seguro que desea bloquear el acceso y la renta a este usuario?",
            buttons: {
                confirm: {
                    label: '<i class="fa fa-check"></i> Aceptar',
                    className: 'btn-success'
                },
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancelar',
                    className: 'btn-secondary'
                }
            },
            size: 'small',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: e.target.dataset.target + '/block',
                        data: {
                            format: 'json'
                        },
                        type: 'GET',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(data.msg);
                                $('#table').bootstrapTable('refresh');
                            } else {
                                toastr.error(data.msg);
                                // console.log(data.msg);
                            }
                        }
                    })
                }
            }
        })

    }

    change_status(e) {

        bootbox.confirm({
            message: "¿Seguro que desea cambiar el estado de disponibilidad?",
            buttons: {
                confirm: {
                    label: '<i class="fa fa-check"></i> Aceptar',
                    className: 'btn-success'
                },
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancelar',
                    className: 'btn-secondary'
                }
            },
            size: 'small',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: e.target.dataset.target,
                        data: {
                            format: 'json'
                        },
                        type: 'GET',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(data.msg);
                                $('#table').bootstrapTable('refresh');
                            } else {
                                toastr.error(data.msg);
                                // console.log(data.msg);
                            }
                        }
                    })
                }
            }
        })

    }

    commercial(e) {
        // console.log(e);
        bootbox.confirm({
            message: "¿Seguro que desea poner este usuario como Comercial del sitio?",
            buttons: {
                confirm: {
                    label: '<i class="fa fa-check"></i> Aceptar',
                    className: 'btn-success'
                },
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancelar',
                    className: 'btn-secondary'
                }
            },
            size: 'small',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: e.target.dataset.target,
                        data: {
                            format: 'json'
                        },
                        type: 'POST',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(data.msg);
                                $('#table').bootstrapTable('refresh');
                            } else {
                                toastr.error(data.msg);
                                // console.log(data.msg);
                            }
                        }
                    })
                }
            }
        })

    }
}